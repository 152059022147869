import React, { useState, useEffect } from "react";
import { Image, Tooltip } from "antd";
import RenderIfComponent from "components/RenderIfComponent";
import { ReactComponent as RequireSvg } from "asset/svg/require.svg";
import { ReactComponent as HelpSvg } from "asset/svg/help.svg";
import { ReactComponent as UploadSvg } from "asset/svg/upload.svg";
import { ReactComponent as CloseSvg } from "asset/svg/close-1.svg";
import { ReactComponent as PlayVideoSvg } from "asset/svg/playVideo.svg";
import helper from "helper/index";
import PrModalUpload from "./PrModalUpload";
import { ATTACHMENT_TYPE } from "contants/app.contant";

const PrUpload = ({
  label,
  subLabel,
  rootClass = "",
  required = false,
  tooltipContent,
  maxFile = 100, // Default max length for uploaded files
  onChange, // Added onChange prop
  defaultFiles, // Added defaultFiles prop
  error,
  isHiddenUploading,
  classLabel
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortedFiles, setSortedFiles] = useState([]);

  const sortFilesByDefault = (files) => {
    return [...(files || [])]?.sort((a, b) => b?.is_defaut - a?.is_defaut);
  };

  useEffect(() => {
    if (defaultFiles) {
      setSortedFiles(sortFilesByDefault(defaultFiles));
    }
  }, [defaultFiles]);

  const handleFileUpload = (newFiles) => {
    if (onChange) {
      onChange(newFiles);
    }
    setIsModalOpen(false);
  };

  const handleFileDelete = (index) => {
    if (onChange && sortedFiles.length > 0) {
      const updatedFiles = [...sortedFiles];
      updatedFiles.splice(index, 1);

      if (!updatedFiles.some((file) => file.is_defaut) && updatedFiles.length > 0) {
        updatedFiles[0].is_defaut = 1;
      }

      onChange(updatedFiles);
    }
  };

  const renderTooltip = (content) => <div className="p-[16px]">{content}</div>;

  const renderFilePreview = (item, index) => {
    let fileURL = "";
    if (item.attachment_type === ATTACHMENT_TYPE.IMAGE) {
      fileURL = !item.file ? item.thumbnail_url || item.url : item.path;
    }
    if (item.attachment_type === ATTACHMENT_TYPE.EMBED) {
      fileURL = helper.getThumbnailUrlFromIframe(item.path);
    }
    return (
      <div key={index} className="relative w-[100px] h-[75px] bg-white mr-[7px]">
        <Image
          width={100}
          height={75}
          className="rounded-[8px] !object-cover cursor-pointer border border-[#E1E1EB]"
          src={fileURL}
          alt={item.name}
          onError={helper.handleImageError}
          preview={false}
        />
        <RenderIfComponent isValid={!isHiddenUploading}>
          <CloseSvg
            className="absolute top-[-10px] right-[-10px] z-[9] cursor-pointer"
            onClick={() => handleFileDelete(index)}
          />
        </RenderIfComponent>
        <RenderIfComponent isValid={item.is_defaut}>
          <div className="text-[11px] font-[600] text-[#FFF] px-[6px] py-[10px] h-[18px] flex items-center justify-center absolute top-[2px] left-[2px] bg-[#EC2929] rounded-[6px]">
            대표
          </div>
        </RenderIfComponent>
        <RenderIfComponent isValid={item.attachment_type === ATTACHMENT_TYPE.EMBED || item.attachment_type === ATTACHMENT_TYPE.VIDEO}>
          <div className="absolute bottom-[2px] right-[2px]">
            <PlayVideoSvg />
          </div>
        </RenderIfComponent>
      </div>
    );
  };

  return (
    <div className={`flex flex-col gap-y-[8px] ${rootClass}`}>
      <div>
        <RenderIfComponent isValid={!!label}>
          <div className="flex items-center">
            <label className={`text-[18px] font-[600] text-[#2C2C33] ${classLabel ? classLabel : ""}`}>{label}</label>
            {required && <RequireSvg />}
            <RenderIfComponent isValid={!!tooltipContent}>
              <Tooltip rootClassName="p-[0px]" placement="bottom" title={renderTooltip(tooltipContent)}>
                <HelpSvg className="ml-[8px] cursor-pointer" />
              </Tooltip>
            </RenderIfComponent>
          </div>
        </RenderIfComponent>
        {!!subLabel?.length && <span className="text-[14px] text-[#888896] font-[400]">{subLabel}</span>}
      </div>
      <RenderIfComponent isValid={!isHiddenUploading}>
        <div
          className={`w-full rounded-[12px] border-[1.5px] ${error ? "border-[#ff4d4f]" : "border-[#E1E1EB]"} hover:border-[#2c2c33] flex items-center p-[16px] gap-x-[8px] justify-center border-dashed cursor-pointer h-[56px]`}
          onClick={() => setIsModalOpen(true)}
        >
          <UploadSvg />
          <div className="text-[16px] font-[500] text-[#555560]">파일 첨부하기</div>
        </div>
      </RenderIfComponent>
      <RenderIfComponent isValid={sortedFiles?.length}>
        <div className="flex flex-wrap gap-[8px] px-[16px] py-[20px] rounded-[12px] border-[1px] border-[#E1E1EB]">
          {sortedFiles?.map((item, index) => renderFilePreview(item, index))}
        </div>
      </RenderIfComponent>
      <PrModalUpload
        isModalOpen={isModalOpen}
        handleCancel={() => {
          setIsModalOpen(false)
        }}
        maxFile={maxFile}
        defaultFiles={defaultFiles}
        handleUploadFile={handleFileUpload}
      />
    </div>
  );
};

export default PrUpload;
